<template>
	<div v-if="src" :class="classes">
		<div :style="style">
			<pdf :src="src" :page="currentPage" @page-loaded="currentPage = $event" @num-pages="totalPages = $event" />
			<div v-if="totalPages > 1" class="pdf-footer">
				<div class="pages">
					Page {{ currentPage }} of {{ totalPages }}
				</div>
				<div class="buttons">
					<BaseButton v-if="currentPage > 1" type="link" label="Previous page" @click="prev">
						<IconArrowLeft :width="16" :height="16" />
					</BaseButton>
					<BaseButton v-if="currentPage < totalPages" type="link" label="Next page" @click="next">
						<IconArrowRight :width="16" :height="16" />
					</BaseButton>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import pdf from 'vue-pdf';
	import ObjectStore from '../../inc/objectStore';
	import BaseButton from '../BaseButton';
	import IconArrowLeft from '../icons/IconArrowLeft';
	import IconArrowRight from '../icons/IconArrowRight';

	export default {
		components: {
			pdf,
			BaseButton,
			IconArrowLeft,
			IconArrowRight
		},
		props: {
			block: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				src: '',
				currentPage: 1,
				totalPages: 1
			};
		},
		computed: {
			classes() {
				return `block-inner current-file align-${this.block.meta.align}`;
			},
			style() {
				let style = '';

				if(this.block.meta.maxWidth) {
					style += 'max-width: ' + this.block.meta.maxWidth + ';';
				}

				return style;
			}
		},
		created() {
			if(this.block.content.files && this.block.content.files.length) {
				ObjectStore.getFileUrl(this.block.content.files[0]).then(url => {
					this.src = url;
				});
			}
		},
		methods: {
			prev() {
				this.currentPage--;
			},
			next() {
				this.currentPage++;
			}
		}
	};
</script>

<style lang="scss" scoped>
	.block-inner {
		display: flex;
		flex-flow: row wrap;

		&.align-center {
			justify-content: center;
		}

		&.align-right {
			justify-content: flex-end;
		}

		& > div {
			flex: 1;
		}
	}

	.pdf-footer {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
	}

	.button {
		margin: 10px;
	}
</style>